import React, { useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import mockData from '@app/help-page/blocks/menu-bar/mock-new.json';

import { Helmet } from 'react-helmet';

import useTranslation from '@app/hooks/use-translation';

import styles from './styles/confluence-style.module.css';

const faviconRtk = require('../imgs/favicon/faviconRtk.png');
const faviconMtc = require('../imgs/favicon/faviconMtc.ico');
const faviconDefault = require('../imgs/favicon/faviconDefault.png');

const HelpPage: React.FC = () => {
  const { content_id } = useParams<{ content_id: string }>();
  const topRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation('common.pageTitle');

  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'auto' });
    }
  }, [content_id]);

  const article = mockData.find((item) => String(item.id) === content_id);

  function replaceDescriptionIfNeeded(article) {
    if (article && article.title === 'Инструкция пользователя') {
      article.description = `
        <div style="margin-top: 120px; display: flex; flex-direction: column; justify-content: center; align-items: center; height: 100%">
          <h1 style="font-size: 22px; font-weight: bold">База знаний по использованию Речевой аналитики</h1>
          <h1 style="font-size: 18px; font-weight: bold; margin-top: 30px">Выберете один из разделов в левом меню</h1>
          <p style="margin-top: 40px">В базе знаний вы сможете найти инструкцию по работе с системой Речевой аналитики и обучающие материалы по использованию системы, включая видео-уроки</p>
          <p style="margin-top: 40px">Также мы собрали здесь описания решений наиболее часто встречающихся аналитических задач с примерами настроек.</p>
          <p style="margin-top: 40px;">В разделе FAQ собраны наиболее часто возникающие вопросы, если вам кажетсся что что-то работает непревильно или вообще не работает - предлаем в нечале заглянуть туда.</p>
        </div>
      `;
    }
    return article;
  }
  const modifiedArticle = replaceDescriptionIfNeeded(article);

  function replaceIframeWithLocalVideo(htmlContent) {
    const videoMap = {
      'Видеоразбор Задания 1': '/video/Zadanie_1.mp4',
      'Видеоразбор Задания 2': '/video/Zadanie_2.mp4',
      'Видеоразбор Задания 3': '/video/Zadanie_3.mp4',
      'Видеоразбор Дополнительных заданий': '/video/Dopolnitelnue_zadania.mp4',
      'Все записи. Метаданные': '/video/Vse zapisi Metadannye.mp4',
      'Краткий обзор РА': '/video/Kratkiy obzor Rechevoy Analitiki.mp4',
      'Просмотр записи': '/video/Prosmotr zapisi.mp4',
      'Составная метрика': '/video/Sostavnaya metrika.mp4',
      'Ручная метрика': '/video/Ruchnaya metrika.mp4',
      'Рабочий стол': '/video/Rabotchi stol.mp4',
      'Создание АП': '/video/Sozdanie Analiticheskogo proekta.mp4',
      Отчёты: '/video/Otchety.mp4',
      Диаграммы: '/video/Diagrammy.mp4',
      'Изменение настроек АП': '/video/Izmenenie nastroek Analiticheskogo proekta.mp4',
      'Универсальный фильтр': '/video/Universalnyy filtr.mp4',
      Словари: '/video/Slovari.mp4',
      Шаблоны: '/video/Shablony.mp4',
      'Разделы меню Записи проекта и Отчёты': '/video/Razdely menyu Zapisi proekta i Otchety.mp4',
      'Облако фраз': '/video/Oblako fraz.mp4',
      Алиасы: '/video/Aliasy.mp4',
      'Правила распознавания': '/video/Pravila raspoznavaniya.mp4',
      'Ручная загрузка записей': '/video/Ruchnaya zagruzka zapisey.mp4',
      'Получатели рассылок': '/video/Poluchately_rassilok.mp4',
      'Отправка отчётов по расписанию': '/video/Otpravka otchetov po raspisaniy.mp4',
      Триггеры: '/video/Trigery.mp4',
      Пользователи: '/video/Polzovately.mp4',
      'Дополнительные настройки для метрики Поиск слов, фраз':
        '/video/Dopolnytelnye nastriiki metriki.mp4',
      'Видеоразбор АП Конкуренты': '/video/AP_Konkurenty.mp4',
      'Видеоразбор АП Оценка работы оператора': '/video/AP Ozneka raboty operatorov.mp4',
      'Видеоразбор АП Недовольство клиента': '/video/AP nedovolstvo klienta.mp4',
      'Видеоразбор АП Настроение клиента': '/video/AP nastroenie klienta.mp4',
      'Видеоразбор АП Тематики': '/video/AP tematiki.mp4',
      'Свободный поиск': '/video/Svobodny poisk.mp4',
    };

    return htmlContent.replace(
      /<p>([^<]*)<iframe.*?src="https:\/\/drive\.google\.com\/file\/d\/.*?<\/iframe>\s*<\/p>/gi,
      (match, title) => {
        const sanitizedTitle = title.replace(/[“”"']/g, '').trim();
        const videoPath = videoMap[sanitizedTitle];
        if (videoPath) {
          return `
        <p>${sanitizedTitle}
          <video width="700px" height="400px " controls>
            <source src="${videoPath}" type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </p>`;
        }
        return match;
      },
    );
  }

  function replaceDownloadLinks(htmlContent) {
    const baseUrl = window.location.origin;
    const downloadMap = {
      'Скачать файл Описание_REST_API_Speech_Analytics_2_3_для_загрузки_записей_разговоров.zip':
        '/download/description_REST_API_Speech_Analytics_2_3.zip',
    };

    const regex =
      /<p>\s*<strong>([^<]+)<\/strong>\s*([^<]+)\s*<\/p>\s*<p class="media-group">.*?<a\s+class="confluence-embedded-file".*?href="([^"]+)".*?>.*?<\/a><\/span><\/p>/gi;
    return htmlContent.replace(regex, (match, fileLabel, fileName) => {
      const cleanedFileName = fileName.trim().replace(/\s+/g, '_');
      const downloadPath =
        downloadMap[fileLabel + ' ' + fileName] || `${baseUrl}/download/${cleanedFileName}`;
      return `<p><a href="${downloadPath}" download><strong>${fileLabel}</strong> ${fileName}</a></p>`;
    });
  }

  function removeStyleAttribute(htmlContent: string) {
    const regex = /<div([^>]*)data-macro-name="code"([^>]*)style="[^"]*"([^>]*)>/gi;
    return htmlContent.replace(regex, '<div$1data-macro-name="code"$2$3>');
  }

  function addBackgroundColor(htmlContent: string) {
    const backgroundColorRegex = /<div class="confluence-information-macro-body">/gi;
    return htmlContent.replace(
      backgroundColorRegex,
      '<div class="confluence-information-macro-body" style="background-color: rgb(233, 242, 255); padding: 8px; border-radius: 5px; margin: 20px;">',
    );
  }

  function removeStylePanelAttribute(htmlContent: string) {
    const regex = /<div class="panel conf-macro output-block"[^>]*style="[^"]*"[^>]*>/gi;
    return htmlContent.replace(regex, (match) => {
      return match.replace(/style="[^"]*"/, '');
    });
  }

  function removeStylePanelContentAttribute(htmlContent: string) {
    const regex = /<div class="panelContent"[^>]*style="[^"]*"[^>]*>/gi;
    return htmlContent.replace(regex, (match) => {
      return match.replace(/style="[^"]*"/, '');
    });
  }

  function addBackgroundPanelColor(htmlContent: string) {
    const backgroundColorRegex = /<div class="panel conf-macro output-block"(.*?)>/gi;
    return htmlContent.replace(
      backgroundColorRegex,
      '<div class="panel conf-macro output-block"$1 style="background-color: rgb(233, 242, 255); padding: 8px; border-radius: 5px; margin: 20px;">',
    );
  }

  function replaceGoogleDriveLinks(htmlContent: string) {
    const driveRegex =
      /<a[^>]+href="https:\/\/drive.google.com\/file\/d\/([a-zA-Z0-9_-]+)\/[^"]+"[^>]*>(.*?)<\/a>/gi;
    return htmlContent.replace(driveRegex, (match, id) => {
      const iframeSrc = `https://drive.google.com/file/d/${id}/preview`;
      return `<iframe src="${iframeSrc}" width="700px" height="400px" allow="autoplay"></iframe>`;
    });
  }

  function prependFaqToLinks(htmlContent: string) {
    return htmlContent.replace(/href="\/content\//g, 'href="/support');
  }

  function hrefEditor(htmlContent: string) {
    return htmlContent.replace(/href="\/wiki\/spaces\/UA\/pages\//g, 'href="/support/');
  }

  function replaceImagePaths(htmlContent: string) {
    return htmlContent.replace(
      /<img([^>]*)src="[^"]*(?:\\|\/)content(?:\\|\/)([0-9]+)(?:\\|\/)([^"]*)"([^>]*)>/gi,
      (match, beforeSrc, contentId, fileName) => {
        const newSrc = `/content/${contentId}/${fileName}`;
        const classAttr = beforeSrc.match(/class="[^"]*"/) || [''];
        const altAttr = beforeSrc.match(/alt="[^"]*"/) || [''];
        const widthAttr = beforeSrc.match(/width="[^"]*"/) || [''];
        return `<img ${classAttr[0]} ${altAttr[0]} ${widthAttr[0]} src="${newSrc}" />`;
      },
    );
  }

  if (!article) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <p className="text-[18px] font-semibold">Выберите статью из меню</p>
      </div>
    );
  }

  let modifiedContent = modifiedArticle.description;
  modifiedContent = replaceIframeWithLocalVideo(modifiedContent);
  modifiedContent = removeStyleAttribute(modifiedContent as string);
  modifiedContent = addBackgroundColor(modifiedContent);
  modifiedContent = replaceGoogleDriveLinks(modifiedContent);
  modifiedContent = prependFaqToLinks(modifiedContent);
  modifiedContent = addBackgroundPanelColor(modifiedContent);
  modifiedContent = removeStylePanelAttribute(modifiedContent);
  modifiedContent = removeStylePanelContentAttribute(modifiedContent);
  modifiedContent = hrefEditor(modifiedContent);
  modifiedContent = replaceIframeWithLocalVideo(modifiedContent);
  modifiedContent = replaceImagePaths(modifiedContent);
  modifiedContent = replaceDownloadLinks(modifiedContent);

  const faviconIconsData = {
    mtc: faviconMtc,
    rtk: faviconRtk,
  };
  return (
    <div className="w-full h-full">
      <Helmet>
        <link
          rel="icon"
          type="image/png"
          href={faviconIconsData[process.env.REACT_APP_THEME || ''] || faviconDefault}
          sizes="16x16"
        />
        <title>{t('title')}</title>
      </Helmet>
      {modifiedContent && modifiedContent.trim() ? (
        <div className="my-[0px]" ref={topRef}>
          <div className="w-full flex justify-center items-center flex-col">
            <div>
              <div className="w-full flex justify-center">
                <h1 className="font-bold text-[24px] mb-[30px] mt-[50px]">
                  {article.title == 'Инструкция пользователя' ? <div></div> : article.title}
                </h1>
              </div>
              <div
                className={`${styles['custom-content']}`}
                dangerouslySetInnerHTML={{ __html: modifiedContent }}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full h-full flex justify-center items-center">
          <p className="text-[18px] font-semibold">Статья отсутствует</p>
        </div>
      )}
    </div>
  );
};

export default HelpPage;
